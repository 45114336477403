import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import People from "./pages/People";
import About from "./pages/About";
import SideBar from "./components/SideBar";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Press from "./pages/Press";
import Detail from "./pages/Detail";

const App = () => {
  return (
    <>
      <div>
        <div className="sidebar-wrapper col-span-12 sm:col-span-3">
          <SideBar />
        </div>
        <div className="col-span-12 sm:col-span-9">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/people" element={<People />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/the-process" element={<Press />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/projects/:id" element={<Detail />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default App;

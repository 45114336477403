import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import { Link } from "react-router-dom";
import Asset1 from "../../assets/001.jpg";
import Asset2 from "../../assets/002.jpg";
import Asset3 from "../../assets/003.jpg";
import Asset4 from "../../assets/004.jpg";
import Asset5 from "../../assets/005.JPG";
import Asset6 from "../../assets/006.jpg";

const Home = () => {
  return (
    <>
      <div
        id="carouselExampleCaptions"
        className="carousel slide relative"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner relative w-full overflow-hidden">
          <div className="carousel-item active relative float-left w-full">
            <LazyLoadImage
              // src="https://live.staticflickr.com/65535/53871045298_529473a500_b.jpg"
              src={Asset1}
              effect="blur"
              alt="FE Apartment"
              className="hidden md:block lg:block"
            />
            <div className="block md:hidden lg:hidden carousel-home-1"></div>
          </div>
          <div className="carousel-item relative float-left w-full">
            <LazyLoadImage
              // src="https://live.staticflickr.com/65535/53870799791_e7a9783d2c_b.jpg"
              src={Asset2}
              effect="blur"
              alt="BI House"
              className="hidden md:block lg:block"
            />
            <div className="block md:hidden lg:hidden carousel-home-2"></div>
          </div>
          <div
            className="carousel-item relative float-left w-full"
            style={{ textAlign: "center" }}
          >
            <LazyLoadImage
              // src="https://live.staticflickr.com/65535/53871045288_1055fe9120_b.jpg"
              src={Asset3}
              effect="blur"
              alt="BI House"
              className="hidden md:block lg:block"
            />
            <div className="block md:hidden lg:hidden carousel-home-3"></div>
          </div>
          <div className="carousel-item relative float-left w-full">
            <LazyLoadImage
              // src="https://live.staticflickr.com/65535/53871045473_6ed45a6a8c_b.jpg"
              src={Asset4}
              effect="blur"
              alt="Simplicity House"
              className="hidden md:block lg:block"
            />
            <div className="block md:hidden lg:hidden carousel-home-4"></div>
          </div>
          <div className="carousel-item relative float-left w-full">
            <LazyLoadImage
              // src="https://live.staticflickr.com/65535/53871148799_89995faaed_b.jpg"
              src={Asset5}
              effect="blur"
              alt="Simplicity House"
              className="hidden md:block lg:block"
            />
            <div className="block md:hidden lg:hidden carousel-home-5"></div>
          </div>

          <div className="carousel-item relative float-left w-full">
            <LazyLoadImage
              // src="https://live.staticflickr.com/65535/53818784484_25087f00f1_k.jpg"
              src={Asset6}
              effect="blur"
              alt="Galaxy - FN House"
              className="hidden md:block lg:block"
            />
            <div className="block md:hidden lg:hidden carousel-home-6"></div>
          </div>
        </div>
        <div>
          <button
            className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Home;

import React from "react";
// import Asset11 from "../../assets/assets-11.png";
// import IconArrowRight from "../../assets/arrow-right.svg";
// import iconDownload from "../../assets/download.svg";
// import { Link } from "react-router-dom";

const Press = () => {
  return (
    <div
      className="lg:mt-0 md:mt-0 ml-3 lg:ml-0 md:ml-0 md:px-8"
      style={{ paddingTop: "100px" }}
    >
      <div className="flex justify-center md:justify-between mb-3 md:mb-0">
        <div className="flex mr-5 md:mr-0" style={{ fontSize: "48px" }}>
          <div className="text-process">T</div>
          <div className="text-process">H</div>
          <div className="text-process">E</div>
        </div>
        <div className="flex" style={{ fontSize: "48px" }}>
          <div className="text-process">P</div>
          <div className="text-process">R</div>
          <div className="text-process">O</div>
          <div className="text-process">C</div>
          <div className="text-process">E</div>
          <div className="text-process">S</div>
          <div>S</div>
        </div>
      </div>
      <div>
        <table className="w-full">
          <thead className="hidden md:contents">
            <tr className="flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
              <th style={{ width: "10%" }}></th>
              <th
                style={{ width: "25%" }}
                className="table-text-normal text-left py-5"
              >
                Phase Name
              </th>
              <th
                style={{ width: "35%" }}
                className="table-text-normal text-left py-5"
              >
                Description
              </th>
              <th
                style={{ width: "25%" }}
                className="table-text-normal text-left py-5"
              >
                Deliverable
              </th>
            </tr>
          </thead>
          <tbody className="flex-1 sm:flex-none">
            <tr
              style={{ borderTop: "1px solid #E1E6EF" }}
              className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
            >
              <td className="table-text-normal py-5 md:py-10">PHASE 1</td>
              <td className="table-text-bold">Schematic Design Architecture</td>
              <td className="table-text-normal">
                Brainstorming terkait kebutuhan dan keinginan dari desain
                Arsitektural yang akan diterapkan pada site terpilih.
              </td>
              <td className="table-text-normal">
                <ul className="list-disc">
                  <li>Exterior Perspective + Facade</li>
                  <li>Architecture Layout Plan 2D + Bare 3D</li>
                </ul>
              </td>
            </tr>

            <tr
              style={{ borderTop: "1px solid #E1E6EF" }}
              className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
            >
              <td className="table-text-normal py-5 md:py-10">PHASE 2</td>
              <td className="table-text-bold">Schematic Design Interior</td>
              <td className="table-text-normal">
                Brainstorming terkait kebutuhan dan keinginan dari desain
                Interior yang akan diterapkan pada layout denah terpilih.
              </td>
              <td className="table-text-normal">
                <ul className="list-disc">
                  <li>Interior Perspective</li>
                  <li>Interior Layout Plan 2D + Bare 3D</li>
                </ul>
              </td>
            </tr>

            <tr
              style={{ borderTop: "1px solid #E1E6EF" }}
              className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
            >
              <td className="table-text-normal py-5 md:py-10">PHASE 3</td>
              <td className="table-text-bold">
                Detail Engineering Drawing Structure
              </td>
              <td className="table-text-normal">
                Meeting for info terkait desain struktur yang akan diterapkan
                pada layout arsitektural yang terpilih.
              </td>
              <td className="table-text-normal">
                <ul className="list-disc">
                  <li>Construction Drawing</li>
                </ul>
              </td>
            </tr>

            <tr
              style={{ borderTop: "1px solid #E1E6EF" }}
              className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
            >
              <td className="table-text-normal py-5 md:py-14">PHASE 4</td>
              <td className="table-text-bold">
                Detail Engineering Drawing Architectural, Interior, & MEP
              </td>
              <td className="table-text-normal">
                Produksi gambar Arsitektur, Interior, dan MEP dari desain layout
                terpilih.
              </td>
              <td className="table-text-normal">
                <ul className="list-disc">
                  <li>Construction Drawing - Architecture</li>
                  <li>Construction Drawing - Interior</li>
                  <li>Schematic for Construction Drawing - MEP</li>
                  <li>Visualisasi 3D (render) Architecture & Interior</li>
                </ul>
              </td>
            </tr>

            <tr
              style={{ borderTop: "1px solid #E1E6EF" }}
              className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
            >
              <td className="table-text-normal py-5 md:py-10">PHASE 5</td>
              <td className="table-text-bold">Additional Technical Document</td>
              <td className="table-text-normal">
                Produksi dokumen teknis terakit desain final terpilih.
              </td>
              <td className="table-text-normal">
                <ul className="list-disc">
                  <li>Bill of Quantity</li>
                  <li>Material Schedule</li>
                  <li>Softcopy Document</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Press;

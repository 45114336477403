import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/Logo-1.png";
import IconBurger from "../assets/ic-burger.svg";
import IconClose from "../assets/ic-close.svg";

const SideBar = () => {
  // const [navbarCollapse, setNavbarCollapse] = useState(false);
  const [isMobileOpen, setMobileOpen] = useState(false);

  const ToggleMobileClose = () => {
    setMobileOpen(false);
    document.body.classList.toggle("header-mobile--is-open", false);
  };

  const ToggleMobileOpen = () => {
    setMobileOpen(!isMobileOpen);
    document.body.classList.toggle("header-mobile--is-open", !isMobileOpen);
  };
  return (
    <div
      className={`sidebar-inner md:pl-7 md:pt-7 w-full md:fixed ${
        isMobileOpen ? "header-mobile--is-open" : ""
      }`}
    >
      <div className="header-mobile shadow-md">
        <div className="header-mobile-inner">
          <div className="header-mobile__icon">
            <img
              onClick={ToggleMobileOpen}
              src={isMobileOpen ? IconClose : IconBurger}
              alt="Studiorna"
            />
          </div>
          <div className="header-mobile__logo">
            <Link to="/" onClick={ToggleMobileClose}>
              <img src={Logo} alt="Studiorna" />
            </Link>
          </div>
        </div>
      </div>
      <div className="sidebar-menu">
        <nav className="sidebar__nav">
          <ul className="md:flex md:justify-between sm:block">
            <div>
              <li className="sidebar__nav-logo pb-10">
                <Link to="/">
                  <img src={Logo} alt="Studiorna" />
                </Link>
              </li>
            </div>
            <div className="md:flex sm:block">
              <li
                className="text-sm text-primary cursor-pointer pb-1 mr-4"
                onClick={ToggleMobileClose}
              >
                <NavLink
                  to="/"
                  style={({ isActive }) => ({
                    color: isActive ? "#1D2433" : "#4B505B",
                    fontWeight: isActive ? "600" : "normal",
                  })}
                >
                  Home
                </NavLink>
              </li>
              <li
                className="text-sm text-primary pb-1 mr-4"
                onClick={ToggleMobileClose}
              >
                <NavLink
                  to="/projects"
                  style={({ isActive }) => ({
                    color: isActive ? "#1D2433" : "#4B505B",
                    fontWeight: isActive ? "600" : "normal",
                  })}
                >
                  Projects
                </NavLink>
              </li>
              <li
                className="text-sm text-primary pb-1 mr-4"
                onClick={ToggleMobileClose}
              >
                <NavLink
                  to="/the-process"
                  style={({ isActive }) => ({
                    color: isActive ? "#1D2433" : "#4B505B",
                    fontWeight: isActive ? "600" : "normal",
                  })}
                >
                  The Process
                </NavLink>
              </li>
              <li
                className="text-sm text-primary pb-1 mr-4"
                onClick={ToggleMobileClose}
              >
                <NavLink
                  to="/about"
                  style={({ isActive }) => ({
                    color: isActive ? "#1D2433" : "#4B505B",
                    fontWeight: isActive ? "600" : "normal",
                  })}
                >
                  About
                </NavLink>
              </li>
              <li
                className="text-sm text-primary pb-1 mr-4"
                onClick={ToggleMobileClose}
              >
                <NavLink
                  to="/contact"
                  style={({ isActive }) => ({
                    color: isActive ? "#1D2433" : "#4B505B",
                    fontWeight: isActive ? "600" : "normal",
                  })}
                >
                  Contact
                </NavLink>
              </li>
            </div>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;

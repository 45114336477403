import React from "react";

import LogoIg from "../../assets/ig.svg";
import LogoYt from "../../assets/yt.svg";

const Contact = () => {
  return (
    <div style={{ paddingTop: "80px" }}>
      <div
        style={{
          paddingRight: "24px",
          paddingBottom: "24px",
          paddingLeft: "10px",
        }}
        className="absolute bottom-0 right-0 hidden lg:block md:block"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.035297138178!2d107.01284711413795!3d-6.259080963020374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e698e7baefb8b73%3A0xfb310b9f37928ab0!2sJl.%20Chairil%20Anwar%20Ruko%20Kalimas%20Residence%20Blk.%20D%20No.3a%2C%20RT.003%2FRW.012%2C%20Margahayu%2C%20Kec.%20Bekasi%20Tim.%2C%20Kota%20Bks%2C%20Jawa%20Barat%2017113!5e0!3m2!1sen!2sid!4v1669707504573!5m2!1sen!2sid"
          width="350px"
          height="150px"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="Studio RNA Address"
          className="google-maps-monochrome"
        ></iframe>
        <div style={{ width: "350px" }}>
          <h3 className="text-secondary" style={{ paddingTop: "24px" }}>
            Studio RNA
          </h3>
          <div>
            <p>
              Jl. Chairil Anwar Ruko Kalimas Residence Blok D No. 3a Bekasi -
              Indonesia 17113
            </p>
          </div>
          <h3 className="text-secondary" style={{ paddingTop: "24px" }}>
            General Enquiries
          </h3>
          <div>
            <p>p. +62 812 6660 7135</p>
            <p>e. hi@studiorna.com</p>
          </div>
          <h3 className="text-secondary" style={{ paddingTop: "24px" }}>
            Vaccant & Internship
          </h3>
          <div>
            <p>e. studiorna.id@gmail.com (with subject “intern_rna”)</p>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <div className="pr-2">
            <a href="https://instagram.com/studio.rna" target="_blank">
              <img src={LogoIg} alt="Instagram Studiorna" />
            </a>
          </div>
          <div>
            <a href="https://youtube.com/@studiorna.9272" target="_blank">
              <img src={LogoYt} alt="Youtube Studiorna" />
            </a>
          </div>
        </div>
      </div>
      <div className="block md:hidden lg:hidden">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.035297138178!2d107.01284711413795!3d-6.259080963020374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e698e7baefb8b73%3A0xfb310b9f37928ab0!2sJl.%20Chairil%20Anwar%20Ruko%20Kalimas%20Residence%20Blk.%20D%20No.3a%2C%20RT.003%2FRW.012%2C%20Margahayu%2C%20Kec.%20Bekasi%20Tim.%2C%20Kota%20Bks%2C%20Jawa%20Barat%2017113!5e0!3m2!1sen!2sid!4v1669707504573!5m2!1sen!2sid"
          width="100%"
          height="200px"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          title="Studio RNA Address"
          className="google-maps-monochrome"
        ></iframe>
        <div className="pl-6" style={{ width: "350px" }}>
          <h3 className="text-secondary" style={{ paddingTop: "24px" }}>
            Studio RNA
          </h3>
          <div>
            <p>
              Jl. Chairil Anwar Ruko Kalimas Residence Blok D No. 3a Bekasi -
              Indonesia 17113
            </p>
          </div>
          <h3 className="text-secondary" style={{ paddingTop: "24px" }}>
            General Enquiries
          </h3>
          <div>
            <p>p. +62 812 6660 7135</p>
            <p>e. hi@studiorna.com</p>
          </div>
          <h3 className="text-secondary" style={{ paddingTop: "24px" }}>
            Vaccant & Internship
          </h3>
          <div>
            <p>e. studiorna.id@gmail.com (with subject “intern_rna”)</p>
          </div>
        </div>
        <div className="flex items-center mt-4 pl-6">
          <div className="pr-2">
            <a href="https://instagram.com/studio.rna" target="_blank">
              <img src={LogoIg} alt="Instagram Studiorna" />
            </a>
          </div>
          <div>
            <a href="https://youtube.com/@studiorna.9272" target="_blank">
              <img src={LogoYt} alt="Youtube Studiorna" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

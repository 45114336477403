import React from "react";
import Asset9 from "../../assets/assets-9.png";

const People = () => {
  return (
    <>
      <div className="flex flex-wrap-reverse justify-between">
        <div className="content-wrapper">
          <h1
            className="text-primary"
            style={{
              borderBottom: "1px solid #C1C2C4",
              paddingBottom: "24px",
              marginBottom: "24px",
            }}
          >
            PEOPLE
          </h1>
          <article style={{ width: "100%" }} className="text-primary">
            <p style={{ paddingBottom: "24px" }}>
              <p>Gerry Hariara Gorby</p>
              <p>Nur Arif Wicaksono</p>
            </p>
            <p style={{ paddingBottom: "24px" }}>
              <p>Architectural Drafter</p>
              <p>Structure Engineer</p>
              <p>ME Engineer</p>
              <p>Estimator</p>
            </p>
            <p className="text-secondary" style={{ paddingBottom: "24px" }}>
              <p>Shafira Raiqah</p>
              <p>Dita Audina</p>
              <p>Khairunnisa Adita Nabila Aulia</p>
              <p>Farid Muhammad</p>
              <p>Nabila Aulia</p>
              <p>Muhammad Thariq</p>
            </p>
          </article>
        </div>
        <div>
          <img
            src={Asset9}
            alt="asset-1"
            className="mb-6 md:pr-6 lg:pr-6"
            style={{
              width: "423px",
              // paddingRight: "24px",
              // marginBottom: "20px",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default People;

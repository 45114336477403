import React from "react";
import Asset8 from "../../assets/about-us.png";

const About = () => {
  return (
    <>
      <div
        className="grid md:grid-cols-2 sm:grid-cols-1"
        style={{ paddingTop: "90px" }}
      >
        <div className="container">
          <img src={Asset8} alt="asset-8" className="image-aboutus" />
        </div>

        <div className="text-primary content-wrapper w-full">
          <div className="about-height">
            <div className="text-about">
              <b>GERRY</b> HARRIARRA GORBY
            </div>
            <div className="block md:flex">
              <div style={{ marginRight: "24px" }}>
                <div>
                  Lulusan Departemen Arsitektur, Universitas Diponegoro Semarang
                  tahun 2015. Sudah berpengalaman di berbagai konsultan arsitek
                  dan perusahaan milik negara ternama di Indonesia. Sebelum
                  membentuk RNA dengan Arif, Gerry mempelajari berbagai hal
                  tentang pengembangan desain, manajemen proyek, analisis
                  anggaran proyek, dan marketing pendukung.
                </div>
                <div
                  style={{ marginTop: "24px" }}
                  className="container-history-desktop"
                >
                  <div className="block md:flex md:justify-between mb-2">
                    <div className="about-years">2015</div>
                    <div className="about-history">
                      PT Quadratura Indonesia (Junior Architect)
                    </div>
                  </div>
                  <div className="block md:flex md:justify-between mb-2">
                    <div className="about-years">2016</div>
                    <div className="about-history">
                      PT Summarecon Agung Tbk. (Project Architect)
                    </div>
                  </div>
                  <div className="block md:flex md:justify-between mb-2">
                    <div className="about-years">2018</div>
                    <div className="about-history">
                      Nindya Karya Persero (Technical Architect)
                    </div>
                  </div>
                  <div className="block md:flex md:justify-between mb-2">
                    <div className="about-years">2018-2021</div>
                    <div className="about-history">
                      PT PP Property (Architect Engineer)
                    </div>
                  </div>
                  <div className="block md:flex md:justify-between mb-2">
                    <div className="about-years">2021-present</div>
                    <div className="about-history">
                      STUDIORNA. (Studio Principal)
                    </div>
                  </div>
                </div>
              </div>
              <div className="history-margin">
                Graduated from the Department of Architecture, Diponegoro
                University Semarang, in 2015. He has experience in various
                architectural consultants and well-known state-owned companies
                in Indonesia. Before establishing RNA with Arif, Gerry learned a
                lot about design development, project management, project budget
                analysis, and supporting marketing.
              </div>
              <div
                className="container-history-mobile"
                style={{ marginTop: "24px" }}
              >
                <div className="block md:flex md:justify-between mb-2">
                  <div className="about-years">2015</div>
                  <div className="about-history">
                    PT Quadratura Indonesia (Junior Architect)
                  </div>
                </div>
                <div className="block md:flex md:justify-between mb-2">
                  <div className="about-years">2016</div>
                  <div className="about-history">
                    PT Summarecon Agung Tbk. (Project Architect)
                  </div>
                </div>
                <div className="block md:flex md:justify-between mb-2">
                  <div className="about-years">2018</div>
                  <div className="about-history">
                    Nindya Karya Persero (Technical Architect)
                  </div>
                </div>
                <div className="block md:flex md:justify-between mb-2">
                  <div className="about-years">2018-2021</div>
                  <div className="about-history">
                    PT PP Property (Architect Engineer)
                  </div>
                </div>
                <div className="block md:flex md:justify-between mb-2">
                  <div className="about-years">2021-present</div>
                  <div className="about-history">
                    STUDIORNA. (Studio Principal)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-height">
            <div className="text-about">
              NUR <b>ARIF</b> WICAKSONO
            </div>
            <div className="block md:flex">
              <div style={{ marginRight: "24px" }}>
                <div>
                  Lulusan Departemen Arsitektur, Universitas Diponegoro Semarang
                  tahun 2015. Arif telah berpengalaman di berbagai konsultan
                  arsitektur dan interior ternama di Indonesia. Sebelum
                  membangun RNA dengan Gerry, Arif belajar banyak mengenai
                  pengembangan desain dan koordinasi proyek. Arif selalu
                  memberikan perhatian pada detail di setiap proses pekerjaan.
                </div>
                <div
                  style={{ marginTop: "48px" }}
                  className="container-history-desktop"
                >
                  <div className="block md:flex md:justify-between mb-2">
                    <div className="about-years">2015</div>
                    <div className="about-history">
                      Rafael Miranti Architects (Assistant Architect)
                    </div>
                  </div>
                  <div className="block md:flex md:justify-between mb-2">
                    <div className="about-years">2016-2020</div>
                    <div className="about-history">
                      TSDS Interior Architects (Senior Interior Designer)
                    </div>
                  </div>
                  <div className="block md:flex md:justify-between mb-2">
                    <div className="about-years">2020-present</div>
                    <div className="about-history">
                      STUDIORNA. (Studio Principal)
                    </div>
                  </div>
                </div>
              </div>
              <div className="history-margin">
                Graduated from the Department of Architecture, Diponegoro
                University Semarang, in 2015. He has experience in various
                well-known architectural and interior consultants in Indonesia.
                Before establishing RNA with Gerry, Arif learned a lot about
                design development and project coordination. Arif always pays
                attention to detail in every work process.
              </div>
              <div
                className="container-history-mobile"
                style={{ marginTop: "24px" }}
              >
                <div className="block md:flex md:justify-between mb-2">
                  <div className="about-years">2015</div>
                  <div className="about-history">
                    Rafael Miranti Architects (Assistant Architect)
                  </div>
                </div>
                <div className="block md:flex md:justify-between mb-2">
                  <div className="about-years">2016-2020</div>
                  <div className="about-history">
                    TSDS Interior Architects (Senior Interior Designer)
                  </div>
                </div>
                <div className="block md:flex md:justify-between mb-2">
                  <div className="about-years">2020-present</div>
                  <div className="about-history">
                    STUDIORNA. (Studio Principal)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;

import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Projects = () => {
  return (
    <div style={{ paddingTop: "96px" }}>
      <div className="grid md:grid-cols-3 sm:grid-cols-1">
        <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/bi-house">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/53858338643_d66d1e130f_b.jpg"
              effect="blur"
              alt="BI House"
            />
            <div className="hover__text">BI House</div>
          </Link>
        </div>
        <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/fe-apartment">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/53858461014_71d04c8db4_b.jpg"
              effect="blur"
              alt="FE Apartment"
            />
            <div className="hover__text">FE Apartment</div>
          </Link>
        </div>
        <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/nw-house">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/53858461124_e338925a15_b.jpg"
              effect="blur"
              alt="NW House"
            />
            <div className="hover__text">NW House</div>
          </Link>
        </div>
        <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/arumaya">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/53858511125_323cf652a2_b.jpg"
              effect="blur"
              alt="Arumaya"
            />
            <div className="hover__text">Arumaya</div>
          </Link>
        </div>
        <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/dt-house">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/53858461034_48d93c8351_b.jpg"
              effect="blur"
              alt="DT House"
            />
            <div className="hover__text">DT House</div>
          </Link>
        </div>
        <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/an-house">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/53858461059_8cdc7f0d73_b.jpg"
              effect="blur"
              alt="AN House"
            />
            <div className="hover__text">AN House</div>
          </Link>
        </div>

        {/* <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/mampang-house">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/53858461034_48d93c8351_b.jpg"
              effect="blur"
              alt="Mampang House"
            />
            <div className="hover__text">Mampang House</div>
          </Link>
        </div>
        <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/simplicity-house">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/53858461059_8cdc7f0d73_b.jpg"
              effect="blur"
              alt="Simplicity House"
            />
            <div className="hover__text">Simplicity House</div>
          </Link>
        </div>
        <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/rumah-taman-sari">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/52662712904_8acf93c6d2_k.jpg"
              effect="blur"
              alt="Rumah Taman Sari"
            />
            <div className="hover__text">Rumah Taman Sari</div>
          </Link>
        </div>
        <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/voila-gelato-cafe">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/52661926592_8dd9f8e0d8_k.jpg"
              effect="blur"
              alt="Voila Gelato Cafe"
            />
            <div className="hover__text">Voila Gelato Cafe</div>
          </Link>
        </div>
        <div className="hover__img relative" style={{ paddingBottom: "24px" }}>
          <Link to="/projects/galaxy-fn-house">
            <LazyLoadImage
              src="https://live.staticflickr.com/65535/52662921333_245b99b736_k.jpg"
              effect="blur"
              alt="Galaxy - FN House"
            />
            <div className="hover__text">Galaxy - FN House</div>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Projects;

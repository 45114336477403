import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataJson from "../../data/data.json";
import { Link } from "react-router-dom";
import IconArrow from "../../assets/arrow-left.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const DetailProject = () => {
  const { id } = useParams();
  const [dataDetail, setDataDetail] = useState(null);
  useEffect(() => {
    const filterData = DataJson.find((item) => item.id === id);
    setDataDetail(filterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="text-secondary pb-3 pt-24 md:mt-0 lg:mt-0 pl-4 md:pl-0 lg:pl-0 md:pt-32 md:ml-3 md:mb-3">
        <Link to="/projects">
          <div className="flex">
            <img src={IconArrow} alt="icon back" className="pr-2" />
            <div>Back To Project</div>
          </div>
        </Link>
      </div>
      <div
        id="carouselExampleCaptions1"
        className="carousel slide relative detail-project hidden md:block lg:block"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner relative w-full overflow-hidden">
          {dataDetail?.images?.length === 4 && (
            <>
              <div className="carousel-item active relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[0].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[1].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[2].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[3].imgItem}
                  effect="blur"
                />
              </div>
            </>
          )}
          {dataDetail?.images?.length === 5 && (
            <>
              <div className="carousel-item active relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[0].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[1].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[2].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[3].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[4].imgItem}
                  effect="blur"
                />
              </div>
            </>
          )}
          {dataDetail?.images?.length === 6 && (
            <>
              <div className="carousel-item active relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[0].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[1].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[2].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[3].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[4].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[5].imgItem}
                  effect="blur"
                />
              </div>
            </>
          )}
          {dataDetail?.images?.length === 7 && (
            <>
              <div className="carousel-item active relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[0].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[1].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[2].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[3].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[4].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[5].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[6].imgItem}
                  effect="blur"
                />
              </div>
            </>
          )}
          {dataDetail?.images?.length === 9 && (
            <>
              <div className="carousel-item active relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[0].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[1].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[2].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[3].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[4].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[5].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[6].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[7].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.images[8].imgItem}
                  effect="blur"
                />
              </div>
            </>
          )}
        </div>
        <div>
          <button
            className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target="#carouselExampleCaptions1"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target="#carouselExampleCaptions1"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div
        id="carouselExampleCaptions2"
        className="carousel slide relative detail-project mobiles block md:hidden lg:hidden"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner relative w-full overflow-hidden">
          {dataDetail?.mobileImages?.length === 4 && (
            <>
              <div className="carousel-item active relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[0].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[1].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[2].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[3].imgItem}
                  effect="blur"
                />
              </div>
            </>
          )}
          {dataDetail?.mobileImages?.length === 5 && (
            <>
              <div className="carousel-item active relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[0].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[1].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[2].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[3].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[4].imgItem}
                  effect="blur"
                />
              </div>
            </>
          )}
          {dataDetail?.mobileImages?.length === 6 && (
            <>
              <div className="carousel-item active relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[0].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[1].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[2].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[3].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[4].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[5].imgItem}
                  effect="blur"
                />
              </div>
            </>
          )}
          {dataDetail?.mobileImages?.length === 7 && (
            <>
              <div className="carousel-item active relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[0].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[1].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[2].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[3].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[4].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[5].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[6].imgItem}
                  effect="blur"
                />
              </div>
            </>
          )}
          {dataDetail?.mobileImages?.length === 9 && (
            <>
              <div className="carousel-item active relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[0].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[1].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[2].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[3].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[4].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[5].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[6].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[7].imgItem}
                  effect="blur"
                />
              </div>
              <div className="carousel-item relative float-left w-full">
                <LazyLoadImage
                  src={dataDetail?.mobileImages[8].imgItem}
                  effect="blur"
                />
              </div>
            </>
          )}
        </div>
        <div>
          <button
            className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
            type="button"
            data-bs-target="#carouselExampleCaptions2"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
            type="button"
            data-bs-target="#carouselExampleCaptions2"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon inline-block bg-no-repeat"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default DetailProject;
